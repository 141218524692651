<template>
  <div>
    <div class="d-flex justify-content-center" v-if="parentIsLoading">
      <CSpinner color="info" />
    </div>
    <div v-else>
      <div class="overflow-hidden mb-3">
        <CAlert v-if="error !== null" color="danger" class="mb-3">
          {{ error }}
        </CAlert>

        <CRow class="mb-3">
          <CCol>
            <div class="ui-type-caption text-uppercase text-gray-600">
              Segnalazione
            </div>
            <div class="ui-type-display-sm">
              S{{ incidentId(incident) }} -
              {{ incidentUserFullname(incident) }} di
              {{ incidentFamilyName(incident) }}
            </div>
            <div class="ui-type-body mb-2">
              <!--
                <OrdersLocationMap
                :title="producerPositionName(incident)"
                :position="producerPosition(incident)"
              />
              <OrdersContacts
                class="ml-3"
                :title="'Contatti personali'"
                :contacts="producerContacts(incident)"
              />
              -->
            </div>
          </CCol>
          <CCol col="auto">
            Aperta il {{ incidentDate(incident, "dd-MM") }} alle
            {{ incidentDate(incident, "HH:mm") }}
            <br />
            Ultima modifica: {{ incidentLastEditDate(incident, "dd-MM") }} alle
            {{ incidentLastEditDate(incident, "HH:mm") }} <br />
            da {{ incidentLastEditUserFullname(incident) }} ({{
              incidentLastEditFamilyName(incident)
            }})
          </CCol>
        </CRow>

        <CRow class="mb-3">
          <CCol>
            <div class="ui-boxed">
              <span
                class="text-gray-600"
                v-html="incidentMessage(incident)"
              /></div
          ></CCol>
        </CRow>

        <CRow class="justify-content-between" v-if="canBeEdit()">
          <CCol col="auto">
            <CButton color="primary" @click.prevent="openIncidentMessageModal"
              >AGGIUNI MESSAGGIO</CButton
            >
          </CCol>

          <CCol col="auto">
            <CButton color="primary" @click.prevent="toggleIndicent">{{
              buttonLabel
            }}</CButton>
          </CCol>
        </CRow>
      </div>

      <CCard class="mb-2">
        <CCardBody>
          <ProductsTable :incident="incident" />
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import EventBus from "../../../../../../helpers/EventBus";
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import ProductsTable from "./ProductsTable";
// import OrdersContacts from "../../../share/OrdersContacts";
// import OrdersLocationMap from "../../../share/OrdersLocationMap";

export default {
  name: "Detail",

  components: {
    ProductsTable,
    // OrdersContacts,
    // OrdersLocationMap,
  },

  mixins: [orderManagementMixin],

  props: {
    incident: { type: Object, required: true },
    parentIsLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      loading: false,
      error: null,
    };
  },

  computed: {
    buttonLabel() {
      return `${this.incident.close ? "APRI" : "CHIUDI"} SEGNALAZIONE`;
    },
  },

  watch: {
    incident(value) {
      this.error = null;
      this.$store.commit("incidents/editSetIncident", value);
    },
  },

  mounted() {
    this.$store.commit("incidents/editSetIncident", this.incident);
  },

  methods: {
    toggleIndicent() {
      this.error = null;
      const formData = {
        incident_id: this.incident.id,
        close: !this.incident.close,
      };
      this.$store
        .dispatch("incidents/toggleIncident", formData)
        .then(() => {
          this.$store.commit("incidents/editHide");
          EventBus.$emit("orders:refresh");
        })
        .catch((error) => {
          console.error(error);
          this.error = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    openIncidentMessageModal() {
      const incident_id = this.incident.id;
      this.$store.commit("incidents/messageShow", { incident_id });
    },

    canBeEdit() {
      return this.incident.can_edit;
    },
  },
};
</script>
